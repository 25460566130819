<template>
    <div class="container-fluid">
         <b-row>
            <b-col lg="12" class="wookie-home">
               <div class="wookie-logo">
                  <img src="@/assets/images/logo-small-white.png" alt="" width="200">              
                  <div class="wookie-actions">
                     <router-link :to="{ name: 'auth.ingresar'}" class="btn btn-primary">
                        Ingresar
                     </router-link>
                     <!--router-link :to="{ name: 'auth.registrar'}" class="btn btn-primary">
                        Registrate
                     </router-link-->
                  </div>
               </div>                
            </b-col>
         </b-row>
      </div>
</template>
<script>
export default {
   name:'Home',
   created() {
      $('body').attr('id', "hola");
   }
}
</script>

<style lang="scss">
   #hola {
      background: rgb(255,70,177);
      background: linear-gradient(90deg, rgba(255,70,177,1) 0%, rgba(205,0,255,1) 100%);
      height: 100vh;
   }
</style>
<style lang="scss" scoped>
   
   .wookie-home  {
      text-align: center;
      .wookie-logo {
         transform: translateY(50%);
      }
      .wookie-actions a {
         margin: 70px 50px 30px;
         background: white;
         color: black;
         min-width: 170px;
         padding: 10px 20px;
         border-radius: 100px;
         border: 0;
         transition: all .2s ease-in-out;
         &:hover {
            color: white;
            background: rgb(72,147,255);
            background: linear-gradient(90deg, rgba(72,147,255,1) 0%, rgba(0,67,255,1) 100%);
         }
      }
   }
</style>